import { SupportedLanguage } from "constants/languages";
import { ROUTES_LOCALES } from "constants/routes";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, matchPath, useLocation, useNavigate } from "react-router-dom";
import { getCurrentRouteData } from "utils/get-current-route-data";

export const UrlLocalizer = () => {
  const { i18n } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const [routeKey, lng] = getCurrentRouteData(location.pathname);
    if (lng === i18n.language) return;

    if (routeKey && lng) {
      const path = ROUTES_LOCALES[lng][routeKey];
      const match = matchPath(path, location.pathname);
      navigate(generatePath(ROUTES_LOCALES[i18n.language as SupportedLanguage][routeKey], match?.params));
    }
  }, [i18n.language]);

  return null;
};
